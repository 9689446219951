import React from 'react';
import Slider from 'react-slick';




function Brand() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll:1,
    center:false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
   

    ]
  }
  return(
      <div className="brand-area">
        <div className="container">
          <Slider className="row brand-active">
            <div className="col-xl">
              <div className="brand-item">
                <img width={200} src="https://logos-world.net/wp-content/uploads/2021/04/Oculus-Logo.png" alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
  )
}

export default Brand;
