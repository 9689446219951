import React from 'react'
import {Link} from 'react-router-dom'



function BlogArea() {
  return (
	<section className="blog-area pt-115 pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title title-style-three text-center mb-70">
                    <h2>Latest News</h2>
                    {/*<p>Compete with 100 players on a remote island for winner takes showdown*/}
                    {/*  known issue where certain skin strategic</p>*/}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="blog-post blog-style-two mb-50">
                    <div className="blog-thumb mb-30">
                      {/*<Link to="/#">*/}
                      <iframe style={{ width: '100%', height: '300px' }} src="https://www.youtube.com/embed/vPrsxF82Cd4"
                              title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                      </iframe>
                      {/*</Link>*/}
                    </div>
                    <div className="blog-post-content">
                      <h4>
                        {/*<Link to="/#">*/}
                        Project: White Desert - Alpha Gameplay Trailer
                        {/*</Link>*/}
                      </h4>
                      <div className="blog-meta">
                        <ul>
                          <li><i className="far fa-clock" />February 19, 2024</li>
                          <li><i className="fas fa-tag" />Alpha Gameplay Trailer</li>
                        </ul>
                      </div>
                      <p>
                        <p>
                          🚀 Get ready for an adventure like no other! 🏜️As Polydream Studio, we’re proud to unveil the first trailer of our upcoming FPS game. Immerse yourself in a captivating journey filled with stealth, action, and puzzle-solving. Are you ready to embark on this adventure?
                        </p>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="blog-post blog-style-two mb-50">
                    <div className="blog-thumb mb-30">
                      {/*<Link to="/#">*/}
                      <iframe style={{ width: '100%', height: '300px' }} src="https://www.youtube.com/embed/ufBVuCKSaOU"
                              title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                      </iframe>
                      {/*</Link>*/}
                    </div>
                    <div className="blog-post-content">
                      <h4>
                        {/*<Link to="/#">*/}
                        Fariz Badalzade - the full <a href="https://tech.az/" target={'blank'}>tech.az</a> interview
                        {/*</Link>*/}
                      </h4>
                      <div className="blog-meta">
                        <ul>
                          <li><i className="far fa-clock" />September 9, 2022</li>
                          <li><i className="fas fa-tag" />Interview</li>
                        </ul>
                      </div>
                      <p>
                        <p>
                          Have you watched our <b>CEO - Fariz Badalzade</b>'s interview about investment in the game industry and the support of different countries in the game industry🤔?
                        </p>
                        <p>
                          Special thanks to the <a href="https://tech.az/" target={'blank'}>tech.az</a> team for their media support🤗🙏🏻!
                        </p>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="blog-post blog-style-two mb-50">
                    <div className="blog-thumb mb-30">
                      {/*<Link to="/#">*/}
                      <iframe style={{ width: '100%', height: '300px' }} src="https://www.youtube.com/embed/l6cspLNvOYg"
                              title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                      </iframe>
                      {/*</Link>*/}
                    </div>
                    <div className="blog-post-content">
                      <h4>
                        {/*<Link to="/#">*/}
                        Game Developers - Professions of the Future Ictimai on ARB TV
                        {/*</Link>*/}
                      </h4>
                      <div className="blog-meta">
                        <ul>
                          <li><i className="far fa-clock" />December 6, 2022</li>
                          <li><i className="fas fa-tag" />Professions of the Future - ARB TV</li>
                        </ul>
                      </div>
                      <p>
                        <p>
                        PolyDream Team was the guest of the "Game Developers" episode of the "Professions of the Future" program broadcast on ARB TV
                        </p>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="blog-post blog-style-two mb-50">
                    <div className="blog-thumb mb-30">
                      {/*<Link to="/#">*/}
                      <iframe style={{ width: '100%', height: '300px' }} src="https://www.youtube.com/embed/VUTp9GMa8l4"
                              title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                      </iframe>
                      {/*</Link>*/}
                    </div>
                    <div className="blog-post-content">
                      <h4>
                        {/*<Link to="/#">*/}
                        Game Industry - MAG Ictimai TV
                        {/*</Link>*/}
                      </h4>
                      <div className="blog-meta">
                        <ul>
                          <li><i className="far fa-clock" />April 24, 2023</li>
                          <li><i className="fas fa-tag" />MAG - Ictimai TV</li>
                        </ul>
                      </div>
                      <p>
                        <p>We are proud to announce that Polydream Studio's <b>CEO - Fariz Badalzadeh</b> 👨‍💼, <b>CTO - Ayyub Najafzade</b> 👨‍💻, and <b>BD Manager - Fidan Rustamli</b> 👩‍💼 participated as guests in the recent episode of "Modern Azerbaijan Youth" on Ictimai TV! 🎉 
                        </p>
                        <p>
                        The episode focused on the Game Sector 🎮, and our experts shared their insights and experiences in the gaming industry 💡                        
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default BlogArea