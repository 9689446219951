import React from 'react';

function CareerArea() {

  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 pl-45">
            {/* <div className="section-title title-style-three mb-20">
              <h2>
                 <span>PolyDream Studio</span>-da oyun dünyasına qoşulun! 
                 </h2>
            </div>
            <p className="section-title title-style-three mb-20">
              <p>
              Azərbaycanda fəaliyyət göstərən, dinamik və innovativ oyun studiosu olan Polydream Studio olaraq böyüyən komandamıza qoşulmaq üçün istedadlı Unity Developer axtarışındayıq. Karyeranızı növbəti səviyyəyə qaldırmağa, yaradıcı və istedadlı oyun həvəskarları qrupunun bir hissəsi olmağa hazırsınızsa, bu sizin üçün fürsətdir!
              </p>
              <br />
              <p>
                Vəzifə: Mid və ya Senior Unity Developer
                <br />
                Ofis: Ataturk prospekti 89a
                <br />

                Tam ştatlı vəzifə
              </p>
              <br />
              <p>
              Məsuliyyətlər:
              </p>
              <p>
              Cazibədar oyunlar yaratmaq üçün istedadlı tərtibatçılar, rəssamlar və dizaynerlərdən ibarət komanda ilə əməkdaşlıq edin<br/>
Dizayn və texniki spesifikasiyalara uyğun olaraq oyun funksionallığını həyata keçirin
              </p>
              <br/>
              <p>
              Tələblər:
              </p>
              <p>
              Obyekt yönümlü proqramlaşdırma bacarığı (OOP) <br/>
Unity və C# program dillərində güclü bilik<br/>
Operativ problem hell etmə bacarığı<br/>
Səmərəli, təmiz və genişlənə bilən kod yazmaq bacarığı<br/>
Git ilə işləmə bacarığı
              </p>
              <br/>
              <p>
              Təklif etdiyimiz:
              </p>
              <p>
              Bacarıq və təcrübəyə əsaslanan əmək haqqı<br/>
Dəstəkləyici və əməkdaşlıq mühiti<br/>
Əgər Unity inkişaf bacarıqlarınızı ön plana çıxarmağa və bütün dünyada oyunçuları valeh edən heyrətamiz oyunlar yaratmağa hazırsınızsa, bizimlə əlaqə saxlayın! Komandamıza qoşulun və gəlin oyun dünyasının gələcəyini birlikdə formalaşdıraq.<br/>
              </p>
              <br/>
              <p>
              Müraciət etmək üçün CV və portfelinizi <Link to="/mailto:info@polydream.studio">info@polydream.studio</Link> ünvanına göndərin.
              </p>
            </p> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerArea