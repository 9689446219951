import React from 'react'

function ShopArea() {
  return (
	<section className="shop-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-sm-3">
              <div className="accessories-item text-center mb-80">
                <div className="accessories-thumb mb-30">
                  <a href="/#"><img src="https://is2-ssl.mzstatic.com/image/thumb/Purple112/v4/93/6c/fe/936cfe38-8103-7db8-b8c2-76548a6b5dd9/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp" alt="" /></a>
                </div>
                <div className="accessories-content">
                  <h5><a href="https://apps.apple.com/us/app/payload-guard/id1628786094">Payload Guard</a></h5>
                  {/*<span>Price: $12.00</span>*/}
                  {/*<a href="/#" className="shop-add-action">Add to cart</a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default ShopArea