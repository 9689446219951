import React from 'react'

function TeamMem() {
  return (
	 <section className="team-member-area pt-115 pb-125">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2>Our team <span>members</span></h2>
                {/*<p>Compete with 100 players on a remote island for winner takes showdown*/}
                {/*  known issue where certain skin strategic</p>*/}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/fariz.jpg" alt="" />
                  <div className="team-member-social">
                    {/*<ul>*/}
                    {/*  <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>*/}
                    {/*  <li><a href="/#"><i className="fab fa-twitter" /></a></li>*/}
                    {/*  <li><a href="/#"><i className="fab fa-pinterest-p" /></a></li>*/}
                    {/*  <li><a href="/#"><i className="fab fa-linkedin-in" /></a></li>*/}
                    {/*</ul>*/}
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">fariz badalzade</a></h4>
                  <span>Founder/CEO</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb" style={{height:'370px'}}>
                  <img src="assets/img/team/mike.jpg" alt="" />
                  <div className="team-member-social">
                    {/*<ul>*/}
                    {/*  <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>*/}
                    {/*  <li><a href="/#"><i className="fab fa-twitter" /></a></li>*/}
                    {/*  <li><a href="/#"><i className="fab fa-pinterest-p" /></a></li>*/}
                    {/*  <li><a href="/#"><i className="fab fa-linkedin-in" /></a></li>*/}
                    {/*</ul>*/}
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Mike Fischer</a></h4>
                  <span>Mentor/Advisor</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/ayyub.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Ayyub Najafzade</a></h4>
                  <span>CTO</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/tural.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Tural Mammedov</a></h4>
                  <span>Game Designer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/nicat.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Nicat Mammedov</a></h4>
                  <span>Lead Developer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/mahir.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Mahir Gafarov</a></h4>
                  <span>Concept Artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/movlud.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Movlud Karimov</a></h4>
                  <span>Environment Artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/aydan.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Aydan Najafli</a></h4>
                  <span>Concept Artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/mirelbey.jpg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Mirelbey Mirgasimov</a></h4>
                  <span>2D/3D artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/ruhulla.jpeg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Ruhulla Hasanzade</a></h4>
                  <span>Concept Artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/jamaladdin.jpeg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Jamaladdin Ramazanov</a></h4>
                  <span>3D artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/heydar.jpeg" alt="" />
                  <div className="team-member-social">
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Heydarkhan Aliyev</a></h4>
                  <span>3D artist</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
  )
}

export default TeamMem