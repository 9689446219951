import React,{ useEffect } from 'react'
import WOW from "wowjs";

function AboutInner() {
  useEffect(()=>{
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
      loop:Infinity,
    }).init();
  
    },[])
  return (
	<section className="inner-about-area fix" id={'about-us'}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
              <div className="inner-about-img" style={{ display: 'flex', justifyContent: 'center'}}>
                <img src="assets/img/images/pd.jpg" className="wow fadeInRight" data-wow-delay=".3s" alt="" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="section-title title-style-three mb-25">
                <h2>about <span>US</span></h2>
              </div>
              <div className="inner-about-content">
                <h5>Hey there, welcome to PolyDream Studio website!
                </h5>
                <p>
                  PolyDream Studio is a game studio founded in 2022. Our objective is to combine technology and creativity to develop high-quality VR, Console and PC Game that will be played for years. Here, talented people have lots of fun working on quality games.
                </p>
                <p>
                  Since 2019, our team, which has been developing games in the field of PC and mobile gaming, has pivoted and started working on a VR, Console and PC  game project in 2022. PolyDream Studio raised an investment from an angel investor at a pre-seed stage with a valuation of $4 million.
                </p>
                <p>
                  PolyDream Studio team has developed a PC game called King&Kingdoms and more than 40 mobile games since 2019.
                </p>
                {/*<a href="/#" className="btn btn-style-two">BUY THEME</a>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="inner-about-shape"><img src="assets/img/images/medale_shape.png" alt="" /></div>
      </section>
  )
}

export default AboutInner